import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["trigger", "container"];
  connect() {
    this.toggle();
  }

  toggle() {
    if (this.triggerTarget.checked) {
      this.containerTarget.classList.remove("hidden");
    } else {
      this.containerTarget.classList.add("hidden");
    }
  }
}
