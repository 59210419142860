import { Controller } from "@hotwired/stimulus";
import { copyToClipboard } from "../helpers/commons";

export default class extends Controller {
  static targets = ["label"];
  static values = { textToCopy: String, textWhenCopied: { type: String, default: "Copied!" } };

  connect() {
    if (this.hasLabelTarget) {
      this.originalLabel = this.labelTarget.innerHTML;
    } else {
      this.originalLabel = null;
    }
  }

  copy(e) {
    e.preventDefault();
    e.stopPropagation();
    copyToClipboard(this.textToCopyValue);
    let label = this.hasLabelTarget ? this.labelTarget : e.currentTarget;
    if (this.originalLabel === null) {
      this.originalLabel = label.innerHTML;
    }
    label.innerHTML = this.textWhenCopiedValue;
    setTimeout(() => {
      label.innerHTML = this.originalLabel;
    }, 2000);
  }
}
