import { Controller } from "@hotwired/stimulus";
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static targets = ["box"];

  toggle() {
    if(this.boxTarget.classList.contains("hidden")) {
      enter(this.boxTarget)
    } else {
      leave(this.boxTarget)
    }
  }
}