import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["trigger"]

  connect() {
    this.toggleClass()
  }

  toggleClass() {
    this.triggerTargets.forEach((element) => {
      if (element.checked) {
        this.addOnClass(element.closest(".target-container"))
      } else {
        this.addOffClass(element.closest(".target-container"))
      }
    })
  }

  addOffClass(container) {
    container.querySelectorAll("[data-class-on]").forEach((element) => {
      let class_off = element.dataset.classOff.split(" ")
      let class_on = element.dataset.classOn.split(" ")
      element.classList.add(...class_off)
      element.classList.remove(...class_on)
    })
  }

  addOnClass(container) {
    container.querySelectorAll("[data-class-on]").forEach((element) => {
      let class_off = element.dataset.classOff.split(" ")
      let class_on = element.dataset.classOn.split(" ")
      element.classList.add(...class_on)
      element.classList.remove(...class_off)
    })
  }
}
