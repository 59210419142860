import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["loader", "iframeReport", "iframeWrapper"];

  connect() {
    this.iframeReportTarget.addEventListener("load", () => {
      this.loaderTarget.classList.add("hidden");
      this.iframeWrapperTarget.classList.remove("hidden");
    });
  }
}
