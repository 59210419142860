import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  hideOff(e) {
    let selector = e.target.dataset.hideTarget || "fake_tag"
    this.element.querySelectorAll(selector).forEach((element) => {
      element.classList.remove("hidden")
    })
  }

  hideOn(e) {
    let selector = e.target.dataset.hideTarget || "fake_tag"
    this.element.querySelectorAll(selector).forEach((element) => {
      element.classList.add("hidden")
    })
  }
}
