import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dialog"];

  showExportConfirmationDialog(e) {
    e.preventDefault();

    this.dialogTarget.showModal();
  }

  submitExportForm(e) {
    this.dialogTarget.close();
  }
}
