import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'input', 'square' ]

  connect() {
    this.update()
  }

  update(){
    if(this.inputTarget.value){
      this.squareTarget.style = `background-color: #${this.inputTarget.value}`
    }
  }
}