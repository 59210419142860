import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  update(event) {
    const value = event.target.value;
    const placement = this.element.querySelector("#live-preview-placement");
    const target = event.target.dataset.livePreviewTarget;
    if (placement == null || target == null) return;

    switch (target) {
      case "color":
        placement.querySelector(".gc-box").style.setProperty("--highlight-text-color", `#${value}`);
        break;
      case "placement-tagline":
        placement.querySelector(".gc-tagline").textContent = value;
        break;
      case "cta-text":
        this.getMarkdown(placement, value, ".gc-text");
        break;
      case "custom-css":
        placement.querySelector("#live-preview-placement  #custom_css").textContent = value;
        break;
      case "image-upload":
        this.previewImage(event.target.files[0], placement);
        break;
      case "completion-text":
        this.getMarkdown(placement, value, ".gc-completion-text");
      case "template":
        const cssTemplate = event.target.dataset.cssValue;
        placement.querySelector("#live-preview-placement  #template_css").textContent = cssTemplate;
      default:
        break;
    }
  }

  showCompletionText() {
    const placement = this.element.querySelector("#live-preview-placement");
    placement.querySelector(".gc-text").classList.toggle("hidden");
    placement.querySelector(".gc-completion-text").classList.toggle("hidden");
  }

  previewImage(file, placement) {
    const reader = new FileReader();
    reader.onloadend = () => {
      const img = placement.querySelector(".gc-image");
      img.src = reader.result;
      const imgPreview = this.element.querySelector(".image-preview");
      imgPreview.src = reader.result;
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  async getMarkdown(placement, text, selector) {
    const segments = window.location.pathname.split("/");
    const newPath = segments.slice(0, 4).join("/") + "/markdown";
    const response = await post(newPath, { body: JSON.stringify({ markdown_text: text }) });
    if (response.ok) {
      const body = await response.json;
      if (body.html != null) {
        placement.querySelector(selector).innerHTML = body.html;
      }
    }
  }
}
