import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  updateSelect() {
    const brand_id = this.element.value
    const hidden = document.querySelector("#campaing-selector").classList.contains("hidden")
    const segments = window.location.pathname.split("/")
    const newPath = segments.slice(0, 4).join("/") + "/campaigns"
    post(newPath, {
      query: { brand_id, hidden },
      responseKind: "turbo-stream"
    })
  }
}
