import { Application } from "@hotwired/stimulus";
import Flatpickr from "stimulus-flatpickr";

window.Stimulus = Application.start();

import controllers from "./*_controller.js";

controllers.forEach((controller) => {
  Stimulus.register(controller.name, controller.module.default);
});

import ColorController from "../admin_controllers/color_controller.js";
import ClipboardController from "../admin_controllers/clipboard_controller";
import ClickToggleController from "../admin_controllers/click_toggle_controller";

Turbo.session.drive = true;

Stimulus.register("click-toggle", ClickToggleController);
Stimulus.register("color", ColorController);
Stimulus.register("clipboard", ClipboardController);
Stimulus.register("flatpickr", Flatpickr);
