import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  switch(event) {
    const url = event.currentTarget.value

    if (!url) return

    Turbo.visit(url)
  }
}