import { Controller } from "@hotwired/stimulus";
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static targets = ["dropdownDesktop", "dropdownMobile"];

  connect() {
  }

  toggleDropdownDesktop() {
    if(this.dropdownDesktopTarget.classList.contains("hidden")) {
      enter(this.dropdownDesktopTarget)
    } else {
      leave(this.dropdownDesktopTarget)
    }
  }

  toggleDropdownMobile() {
    if(this.dropdownMobileTarget.classList.contains("hidden")) {
      enter(this.dropdownMobileTarget)
    } else {
      leave(this.dropdownMobileTarget)
    }
  }

}
